import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        days: 15,
    },
    mutations: {
        updateDays (state, days) {
            state.days = days
        },
    }
})

export default store