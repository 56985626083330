import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

Vue.use(VueRouter)

if (process.env.NODE_ENV === 'development')
    axios.defaults.baseURL = 'http://localhost:8000'
else
    axios.defaults.baseURL = 'https://analytics.daiteo.io'

const jwt_decode = (token) => {
    try { return JSON.parse(window.atob(token.split('.')[1])) }
    catch(e) { return null }
}

const token_exp = (exp) => {
    const d = new Date(0)
    const current = new Date()

    d.setUTCSeconds(exp)

    if (current > d)
        return false
    return true
}

const refresh_token = async () => {
    const decode = jwt_decode(localStorage.getItem('refreshToken'))

    if (decode && !token_exp(decode.exp)) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        return false
    }
    let payload = {'refresh': localStorage.getItem('refreshToken')}
    await axios.post('/api/token/refresh/', payload)
    .then((response) => {
        localStorage.setItem('accessToken', response.data.access)
    })
    return true
}

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import('../views/ViewLogin.vue')
    },
    {
        path: '/global', 
        name: 'Global',
        component: () => import('../views/ViewGlobal.vue')
    },
    {
        path: '/client', 
        name: 'Client', 
        component: () => import('../views/ViewClient.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const publicUrl = ['/']
    const auth_req = !publicUrl.includes(to.path)

    if (auth_req && (!localStorage.getItem('accessToken') || !localStorage.getItem('refreshToken'))) {
        localStorage.removeItem('accessToken')
        localStorage.removeItem('refreshToken')
        next('/')
    } else {
        const decode = jwt_decode(localStorage.getItem('accessToken'))

        if (decode && !token_exp(decode.exp, 1)) {
            if (!refresh_token())
                next('/')
        }
        next()
    }
})

export { router, jwt_decode, token_exp, refresh_token }